import { Dialog, Transition } from '@headlessui/react'
import { XIcon } from '@heroicons/react/solid'
import ProductModalContent from 'components/product/ProductCard/ProductModalContent'

import { Fragment, useRef, useState } from 'react'
import { IProduct } from 'store/api'

type Props = {
  isProductModalOpen: boolean
  closeProductModal: () => void
  product: IProduct | null
}

const ProductModal = ({
  isProductModalOpen,
  closeProductModal,
  product,
}: Props) => {
  const closeButton = useRef(null)

  const [touchStart, setTouchStart] = useState(0)
  const [touchEnd, setTouchEnd] = useState(0)

  const handleTouchStart = (e: any) => {
    setTouchStart(e.targetTouches[0].clientY)
  }
  const handleTouchMove = (e: any) => {
    setTouchEnd(e.targetTouches[0].clientY)
  }
  const handleTouchEnd = () => {
    if (touchEnd - touchStart > 100) {
      closeProductModal()
    }
  }

  return (
    <Transition.Root show={isProductModalOpen} as={Fragment}>
      <Dialog
        as='div'
        className='fixed md:relative z-50'
        onClose={closeProductModal}
        initialFocus={closeButton}
      >
        <div className='overflow-y-auto fixed inset-0 flex items-end md:items-center justify-center md:p-2'>
          <Transition.Child
            as={Fragment}
            enter='ease-in md:ease-out duration-75 md:duration-300'
            enterFrom='opacity-0'
            enterTo='opacity-100'
            leave='ease-in duration-75 md:duration-200'
            leaveFrom='opacity-100'
            leaveTo='opacity-0'
          >
            <Dialog.Overlay className='fixed inset-0 bg-dark bg-opacity-75 transition-opacity' />
          </Transition.Child>

          <span
            className='hidden md:inline-block md:align-middle md:h-screen'
            aria-hidden='true'
          >
            &#8203;
          </span>

          <Transition.Child
            as={Fragment}
            enter='ease-in duration-500 bg-white md:ease-out md:duration-300 md:bg-transparent'
            enterFrom='translate-y-full md:scale-0'
            enterTo='translate-y-0 md:scale-100'
            leave='ease-in duration-300 bg-white md:duration-200 md:bg-transparent'
            leaveFrom='translate-y-0 md:scale-100'
            leaveTo='translate-y-full md:scale-0 bg-white md:bg-transparent'
          >
            <div className='bg-white max-h-[80%] w-full h-auto md:w-[70vw] lg:max-w-[990px]'>
              <div
                className='z-[100] bg-transparent absolute h-[200px] w-full md:hidden'
                onTouchStart={handleTouchStart}
                onTouchEnd={handleTouchEnd}
                onTouchMove={handleTouchMove}
              >
                <div className='h-1 w-10 mx-auto bg-mediumDark mt-1.5 rounded-md'></div>
              </div>
              <div className='relative md:block transform transition-all md:w-[70vw] max-w-full mx-auto max-h-[80%] lg:h-max'>
                <button
                  aria-label='Закрыть'
                  type='button'
                  className='absolute -top-8 right-2 lg:top-6 lg:right-6 focus:outline-none z-10'
                  onClick={closeProductModal}
                  ref={closeButton}
                >
                  <XIcon
                    className='h-6 w-6 text-white lg:text-dark lg:hover:text-accent'
                    aria-hidden='true'
                  />
                </button>

                <div className='flex items-center justify-center'>
                  <div className='h-max w-full relative'>
                    {product !== null && (
                      <ProductModalContent
                        onCloseModal={closeProductModal}
                        product={product}
                      />
                    )}
                  </div>
                </div>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  )
}

export default ProductModal
