import clsx from 'clsx'
import LoadingDots from 'components/common/LoadingDots'
import Button from 'components/forms/Button'
import MinusIcon from 'components/icons/MinusIcon'
import PlusIcon from 'components/icons/PlusIcon'
import useSlug from 'hooks/useSlug'
import formatMoney from 'lib/formatMoney'
import { inProduction } from 'lib/misc'
import {
  isNonEmptyArray,
  isNonEmptyObj,
  isPresent
} from 'lib/utils'

import useCheckSiteStatus from 'hooks/useCheckSiteStatus'
import dynamic from 'next/dynamic'
import { FC, useCallback, useEffect, useMemo, useState } from 'react'
import {
  useAddCartItemMutation,
  useGetProductDetailQuery,
} from 'store/api/actions'
import { IProduct } from 'store/api/types'
import ProductImages from './ProducstImages'
import ProductInfo from './ProductInfo'
import useIngredients from './useIngredients'
import useVariations from './useVariations'

const ProductIng = dynamic(() => import('./ProductIng'))
const ProductVariations = dynamic(() => import('./ProductVariations'))

interface IProductModalContentProps {
  product: IProduct
  onCloseModal: () => void
}

const ProductModalContent: FC<IProductModalContentProps> = ({
  product,
  onCloseModal,
}) => {
  const { queryLng } = useSlug()
  const {
    id,
    name,
    type,
    variation_id,
    price: productPrice,
    weight: productWeight,
    options,
    groupsingsProps,
  } = product

  const { data: productDetail, isLoading: isDetailsLoading } =
    useGetProductDetailQuery(
      { lng: queryLng, id },
      { skip: id === undefined }
    )

  const [quantity, setQuantity] = useState(1)

  const handleDecreaseQty = useCallback(() => {
    quantity > 1 && setQuantity((prev) => prev - 1)
  }, [quantity])

  const {
    variationId,
    onVariationChanged,
    variationPrice: price,
    variationWeight: weight,
    showVariations,
    nutritionValue,
  } = useVariations(
    productDetail,
    type,
    id,
    productPrice,
    productWeight
  )

  const {
    selectedIngs,
    handleToggleIng,
    multiplicity,
  //  maxQty,
    minQty,
    ingTotalCount,
    ingTotalSum,
    ingredientsList,
  } = useIngredients(options, groupsingsProps, quantity)

  // const ingDescription = useMemo(() => {
  //   if (+minQty > 0 && minQty != maxQty && +maxQty > 0)
  //     return (
  //       <span
  //         className={clsx({
  //           'text-warning': ingTotalCount < +minQty,
  //         })}
  //       >
  //         Минимум {minQty} (макс. {maxQty}):
  //       </span>
  //     )
  //     if (+minQty > 0 && (minQty == maxQty || +maxQty == 0))
  //     return (
  //       <span
  //         className={clsx({
  //           'text-warning': ingTotalCount < +minQty,
  //         })}
  //       >
  //         Минимум {minQty}:
  //       </span>
  //     )
  //   return null;
  // }, [minQty, maxQty, ingTotalCount])

  const totalPrice = useMemo(
    () =>
      isPresent(price)
        ? isNonEmptyObj(groupsingsProps)
          ? price + ingTotalSum
          : price
        : undefined,
    [price, ingTotalSum, groupsingsProps]
  )

  const [addCartItem, { isLoading: addCartItemLoading }] =
    useAddCartItemMutation()

  const _addCartItem = useCallback(async () => {
    await addCartItem({
      id,
      quantity,
      lng: queryLng,
      price: price!,
      variation_id: type === 'simple' ? variation_id : variationId,
      type,
      ingredients:
        selectedIngs.length > 0
          ? selectedIngs.map(([ing, qty]) => [
              ing.ingredientId,
              ing.ingredientVariationId,
              qty,
            ])
          : [],
    })
    if (inProduction) {
      window.gtag!('event', 'add_to_cart', {
        items: [
          {
            id: id,
            name: name,
            price: price!,
            quantity: quantity,
            variant: type === 'simple' ? variation_id : variationId,
          },
        ],
      })
      window.ym!(16650304,'reachGoal','addToCartYa')
      window.dataLayer.push({ ecommerce: null })
      window.dataLayer.push({
        event: 'addToCart',
        ecommerce: {
          currencyCode: 'RUB',
          add: {
            products: [
              {
                id: id,
                name: name,
                price: price!,
                quantity: quantity,
                variant:
                  type === 'simple' ? variation_id : variationId,
              },
            ],
          },
        },
      })
    }

    onCloseModal()
  }, [
    addCartItem,
    id,
    price,
    name,
    quantity,
    queryLng,
    type,
    variation_id,
    selectedIngs,
    variationId,
    onCloseModal,
  ])

  const maxQtyAchieved = useMemo(() => {
    return isPresent(product)
      ? quantity == product.options?.prod_max_quantity
      : false
  }, [product, quantity])

  useEffect(() => {
    if (typeof window !== 'undefined' && inProduction) {
      window.gtag!('event', 'view_item', {
        items: [
          {
            id: id,
            name: name,
            price: price,
          },
        ],
      })
      window.dataLayer.push({ ecommerce: null })
      window.dataLayer.push({
        ecommerce: {
          currencyCode: 'RUB',
          detail: {
            products: [
              {
                id: id,
                name: name,
                price: price,
              },
            ],
          },
        },
      })
    }
  }, [])

  const handleAddCartItem = useCallback(async () => {
    await _addCartItem()
    setQuantity(1)
  }, [_addCartItem])

  const ingMultiplicityWarning = useMemo(
    () =>
      ingTotalCount !== 0 &&
      multiplicity !== 0 &&
      ingTotalCount % multiplicity !== 0,
    [ingTotalCount, multiplicity]
  )

  const { isAcceptingOrders, InfoBlock } = useCheckSiteStatus()

  if (isDetailsLoading)
    return <LoadingDots className='h-5 w-5 mx-2.5 bg-accent' />

  if (productDetail === undefined)
    return <div>Не удалось загрузить данные о товаре</div>

  return (
    <div className='bg-white rounded-tr-lg rounded-tl-lg md:rounded-none'>
      <div className='flex flex-col lg:flex-row px-4 md:px-6 lg:px-0'>
        <div className='lg:w-[40%] pt-4 md:pt-6 lg:pb-14 lg:pt-11 lg:pl-9'>
          <ProductImages
            product={product}
            productDetail={productDetail}
            classNames='lg:mr-9'
          />
        </div>

        <div className='relative flex flex-col justify-between lg:w-[60%] pt-4 lg:pt-11 lg:border-l border-grey'>
          <div className='lg:px-8'>
            <ProductInfo
              product={product}
              fullDesc={productDetail?.product?.full_desc}
              weight={weight}
              {...nutritionValue}
            />

            {showVariations && (
              <ProductVariations
                variations={
                  productDetail.productsProps[product?.id]?.meta
                }
                tagLabels={productDetail.product.meta.tagsLabels}
                onVariationChange={onVariationChanged}
              />
            )}

            {isPresent(ingredientsList) &&
              isNonEmptyArray(ingredientsList) && (
                <div>
                  {/* <p className='text-sm text-bold leading-6'>
                    {ingDescription}
                  </p> */}
                  <div className='divide-y-[1px] lg:divide-y-0 my-3 flex flex-col'>
                    {ingredientsList.map((ing: any) => (
                      <ProductIng
                        key={ing.ingredientId}
                        ing={ing}
                        groupsingsProps={groupsingsProps}
                        selectedIngs={selectedIngs}
                        onClick={handleToggleIng}
                      />
                    ))}
                  </div>
                </div>
              )}
          </div>
        </div>
      </div>

      <div className='bg-white sticky bottom-0 md:static flex flex-col md:flex-row justify-between lg:items-center gap-2 lg:gap-4 p-4 lg:py-5 lg:px-10 shadow-inner w-full max-h-[95px]'>
        <div className='text-sm leading-5 text-dark text-light'>
          {/* {selectedIngs.length > 0 &&
            `Ингредиенты x${ingTotalCount} (${formatMoney(
              ingTotalSum
            )})`} */}
          {ingTotalCount == 0 && +minQty > 0 && (
                  <div className='text-warning'>
                    Необходимо выбрать блюдо
                  </div>
                )}
                {ingMultiplicityWarning && (
                  <div className='text-warning'>
                    Количество должно быть кратно {multiplicity}
                  </div>
                )}
        </div>
        {isAcceptingOrders ? <div className='lg:w-[40%] flex items-center justify-end gap-4 flex-shrink-0'>
          <Button
            aria-label='Уменьшить количество'
            onClick={handleDecreaseQty}
            disabled={quantity < 2 || addCartItemLoading}
            className={clsx(
              'rounded-lg bg-lightYellow w-10 h-10 p-3.5',
              {
                'hover:bg-primary': quantity > 1,
              }
            )}
          >
            <MinusIcon
              className={clsx('w-full h-full mx-auto my-auto', {
                'text-bodyGrey': quantity === 1,
                'text-dark': quantity > 1,
              })}
            />
          </Button>

          <div className='text-sm leading-6 tracking-tight text-dark'>
            {quantity}
          </div>

          <Button
            aria-label='Увеличить количество'
            onClick={() => setQuantity((prev) => prev + 1)}
            className={clsx(
              'rounded-lg bg-lightYellow w-10 h-10 p-3.5',
              {
                'hover:bg-primary':
                  !addCartItemLoading && !maxQtyAchieved,
              }
            )}
            disabled={addCartItemLoading || maxQtyAchieved}
          >
            <PlusIcon className='w-full h-full mx-auto my-auto' />
          </Button>

          <Button
            className='rounded-lg max-w-[300px] min-w-max flex-grow h-10 px-4 btn-primary py-2 text-sm leading-5 font-semibold tracking-tight relative'
            onClick={handleAddCartItem}
            disabled={
              addCartItemLoading ||
              (+minQty > 0 && ingTotalCount < +minQty) || ingMultiplicityWarning
            }
            loading={addCartItemLoading}
            loadingClasses='h-3 w-3 mx-1 bg-accent'
          >
            <div className='h-full flex justify-between items-center gap-4'>
              <span>В корзину</span>
              <span className='text-sm'>
                {isPresent(totalPrice)
                  ? formatMoney(totalPrice)
                  : '... ₽'}
              </span>
            </div>
          </Button>
        </div>
        : <InfoBlock className='text-left leading-tight text-sm' />}
      </div>
    </div>
  )
}

export default ProductModalContent
